/* 버튼 - 메인컬러 */
.btn-check:active+.btn-bocare, .btn-check:checked+.btn-bocare, .btn-bocare.active, .btn-bocare:active, .show>.btn-bocare.dropdown-toggle {
    color: $whiteColor;
    background-color: $mainActiveColor;
    border-color: $mainActiveColor;
}
.btn-bocare {
    color: $whiteColor;
    background-color: $mainColor;
    border-color: $mainColor;
}
.btn-bocare:hover {
    color: $whiteColor;
    background-color: $mainActiveColor;
    border-color: $mainActiveColor;
}
/* 버튼 - 아웃라인 메인 */
.btn-outline-bocare {
    color: $mainActiveColor;
    border-color: $mainActiveColor;
}
.btn-outline-bocare:hover {
    color: $whiteColor;
    background-color: $mainActiveColor;
    border-color: $mainActiveColor;
}
/* 버튼 - 핑크 */
.btn-check:active+.btn-pink, .btn-check:checked+.btn-pink, .btn-pink.active, .btn-pink:active, .show>.btn-pink.dropdown-toggle {
    color: $whiteColor !important;
    background-color: $pinkActiveColor;
    border-color: $pinkActiveColor;
}
.btn-pink {
    color: $whiteColor;
    background-color: $pinkColor;
    border-color: $pinkColor;
}
.btn-pink:hover {
    color: $whiteColor !important;
    background-color: $pinkActiveColor;
    border-color: $pinkActiveColor;
}
/* 버튼 - 아웃라인 핑크 */
.btn-outline-pink {
    color: $pinkActiveColor;
    border-color: $pinkActiveColor;
}
.btn-outline-pink:hover {
    color: $whiteColor !important;
    background-color: $pinkActiveColor;
    border-color: $pinkActiveColor;
}

/* 카카오톡 버튼 */
.btn-kakao {
    color: #512c00;
    background-color: #fee141;
    border-color: #fee141;
}
.btn-kakao:hover {
    color: #512c00;
    background-color: #ebc71d;
    border-color: #ebc71d;
}
.btn-icon-image{
    width: 23px;
    height: auto;
}

/*저장, 취소, 이전, 다음, 버튼 크기*/
.btn-footer-size{
    width: 120px;
    padding: 7px 0;
}

@include web {
    .btn-footer-size{
        width: 120px;
    }
}

@include mobile {
    .btn-footer-size{
        width: 100%;
    }
}