// 특정 부분 컬러 변경 시 사용

/* 링크 - 메인컬러 */
.link-color-bocare{
    color: $mainColor;
}
.link-color-bocare:hover{
    color: $mainActiveColor;
}
/* 링크 - 핑크 */
.link-color-pink{
    color: $pinkColor;
}
.link-color-pink:hover{
    color: $pinkActiveColor;
}

.border-pink {
    border-color: $pinkColor;
}

/* 폰트 컬러*/
.text-bocare{
    color: $mainColor !important;
}
.text-hover-bocare:hover, .text-hover-bocare:active {
    color: $mainColor !important;
}
.text-pink{
    color: $pinkColor !important;
}

.bg-bocare {
    background-color: $mainColor !important;
}

.bg-pink{
    background-color: $pinkColor !important;
}

.border-bocare {
    border-color: $mainColor;
}