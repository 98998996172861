.phone-icon {
    max-width: 20px !important;
    width: 100%;
}

.icon-img {
    max-width: 50px !important;
    margin: 0 auto !important;
}

.profile_img {
    position: relative;
    width: 130px;
    height: 130px;
    border-radius: 50px;
    overflow:hidden;
}

.webzien-profile-img-box {
    width: 90px;
    height: 90px;
    border-radius: 40px;
    margin: 0 auto;
    overflow: hidden;
}

.webzine-profile-img-size {
    width: 100%;
    height: 100%;
}

.profile_img:after {
    content: "";
    display: block;
    padding-bottom: 100%;

}
.rounded-top-xl{
    border-radius: 2.5rem 2.5rem 0 0;
}
.rounded-xl{
    border-radius: 2.5rem;
}

.profile_img img {
    // position: absolute;
    width: 100% !important;
    height: 100% !important;
}

.mt-minus-35{
    margin-top:-35px;
}
.business-icon-size{
    width: 40px;
    height: 40px;
    min-width: 40px;
}
.pr-icon,
.px-icon {
    padding-right: 3rem !important;
}

.pl-icon,
.px-icon {
    padding-left: 3rem !important;
}

@media screen and (min-width: 10in) {

    .pr-icon,
    .px-icon {
        padding-right: 2.5rem !important;
    }

    .pl-icon,
    .px-icon {
        padding-left: 2.5rem !important;
    }
}

@media screen and (max-width: 10in) {

    .pr-icon,
    .px-icon {
        padding-right: 1rem !important;
    }

    .pl-icon,
    .px-icon {
        padding-left: 1rem !important;
    }
}