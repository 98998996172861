@import "../mixin";
/*----------------------- 보케어 헤더 스타일 ---------------------------*/

.bocare_header {
    padding: 16px;
    @include header-height;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
    background-color: #ffffff;
    width: calc(100% - #{$sideWidth});
    z-index: 98;
}

.header_font_size {
    font-size: 0.875em;
}

@include mobile {
    .bocare_header {
        width: 100%;
    }

    .header_font_size {
        font-size: 1.500em;
    }
}

/*----------------------- 모바일 메뉴 ---------------------------*/
.mobile-logo-width {
    width: 120px;
}
.mobile-side-profile-width {
    width: 50px;
    height: 50px;
}
.join-icon-size{
    height: 15px;
    width: 15px;
}
.new-icon {
    width: 20px;
    height: 20px;
    position: fixed;
    right: 40px;
    top: 10px;
}

.mobile-profile {
    width: 20%;
}

.mobile-count-icon {
    width: 20px;
    height: 20px;
    line-height: 20px;
}

/* 모바일 메뉴 폰트 사이즈 */
.mobileMenu_title_font_size {
    font-size: 1.000em;
}

.mobileMenu_sub_font_size {
    font-size: 0.875em;
}

.offcanvas-end {
    top: 0;
    right: 0;
    width: 100%;
    border-left: 0px !important
}

.mobile-menu-icon-size {
    width: 10%;
}