@import "variable";

@mixin mobile {
    @media (max-width: #{$mobile}) {
        @content;
    }
}

@mixin web {
    @media (min-width: #{$mobile}) {
        @content;
    }
}

@mixin header-height {
    @include mobile {
        height: $mobileHeaderHeight;
    }

    @include web {
        height: $webHeaderHeight;
    }
}