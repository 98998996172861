.nav-link {
    color: black !important;
    background-color: white;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.nav-link.active {
    color: $whiteColor !important;
    background-color: $pinkActiveColor;
    border-color: $pinkActiveColor;
}

.nav-tabs-pink .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: $pinkColor $pinkColor $pinkColor;
    isolation: isolate;
}

.nav-tabs .nav-link {
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
    border: 1px $pinkColor solid;
}

/*핑크 탭 디자인*/
.nav-tabs-pink {
    border-bottom: none;
}

.nav-tabs-pink .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: $pinkColor $pinkColor #fff;
}

.nav-tabs-pink .nav-link.active {
    color: #ffffff !important;
    background-color: $pinkActiveColor;
    border-color: $pinkActiveColor;
}