@import "../variable";
/*----------------------- 보케어 푸터 스타일 ---------------------------*/

.bocare_footer {
    background-color: #ffffff;
    border-top: 1px solid #dee2e6 !important;
    height: 5%;
}

.mobile_footer {
    position: static;
    z-index: 0;
    padding: 0%;
    height: 70px !important;
}

@media (max-width: 10in) {
    .mobile_footer {
        position: fixed;
        z-index: 97;
        padding: 2%;
        left: 0;
        bottom: 0;
        width: 100%;
    }
}

.footer_font_size {
    font-size: 0.875em;
}