@charset "UTF-8";

@import "./include/library";
@import "./include/mixin";
@import "./include/variable";
@import "./include/items";
@import "./include/font";
@import "./include/color";
@import "./include/header/header";
@import "./include/footer/footer";

/*----------------------- 보케어 공통 스타일 ---------------------------*/

a {
    text-decoration: none;
}

body {
    padding: 0 0 calc(constant(safe-area-inset-top) + 5px);
    padding: 0 0 calc(env(safe-area-inset-top) + 5px);
    padding: 0 0 calc(constant(safe-area-inset-bottom));
    padding: 0 0 calc(env(safe-area-inset-bottom));
    padding: 0 0 calc(constant(safe-area-inset-left));
    padding: 0 0 calc(env(safe-area-inset-left));
    padding: 0 0 calc(constant(safe-area-inset-right));
    padding: 0 0 calc(env(safe-area-inset-right));
    -webkit-touch-callout:none;
}

/*header 카톡문의, 서비스매뉴얼 링크 디자인*/
.header-sub-link {
    color: #444;
}

.header-sub-link:hover {
    color: #377dff;
}

/* 링크 - 스타일 */
.link-underline:hover {
    text-decoration: underline;
}

/* 보케어 우측 컨텐츠 페이지 공통 사이즈 */
.bocare_main {
    padding: 20px;
    padding-top: calc(20px + #{$mobileHeaderHeight});
    word-break: break-all;
    margin-bottom: auto;
}

.ms-web-auto {
    margin-left: 0 !important;
    width: 100%;
}

.search-box-size {
    width: 400px;
    height: 40px;
}

.new_button {
    display: inline-block;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 0.71429rem;
    border-radius: 50%;

    &.top {
        position: absolute;
        top: -4px;
    }
}

.new_button_rounded {
    display: inline-block;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 24px;
    font-size: 0.6rem;
    border-radius: 50%;

    &.top {
        position: absolute;
        top: -10px;
    }
}

// text line 1~5
@for $i from 1 through 5 {
    .text-ellipsis_line#{$i} {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: $i;
        -webkit-box-orient: vertical;
        word-break: break-all;
        overflow: hidden;
    }
}

@include mobile {
    .bocare_main {
        padding-bottom: 100px !important;
    }

    .search-box-size {
        width: 100%;
        padding: 15px 0px 50px 0px !important
    }
}

@include web {
    .d-web-block {
        display: block !important;
    }

    .d-web-inline-block {
        display: inline-block !important;
    }

    .d-web-flex {
        display: flex !important;
    }

    .d-web-none {
        display: none !important;
    }

    .ms-web-auto {
        width: calc(100% - #{$sideWidth});
        margin-left: $sideWidth  !important;
    }
}

.point_cursor {
    cursor: pointer;
}

.word_break {
    word-break: keep-all;
    white-space: nowrap;
}

.text-purple {
    color: #9a69cb;
}

.bg-opacity-0_3_bocare {
    background-color: #ebf2ff;
    border: 1px solid #377dff;
}

.moreAndMore {
    display: none;
}