.community-logo-size {
  width: 150px;
}

.community-search-box-size {
  width: 100%;
}

.community-main-icon-size {
  width: 75%;
  height: auto;
}

@media (min-width: 10in) {
  .community-logo-size {
    width: 250px;
  }
  .community-main-icon-size {
    width: 120px;
    height: auto;
  }
}

@media (min-width: 992px) {
  .container-community {
    max-width: 840px;
  }
}

.main_border {
  border: 1px solid #dee2e6 !important;
}

.main_border:hover {
  border: 1px solid #377dff !important;
}

.main_height {
  min-Height: 100vh;
}
