// color variable
$mainColor:#377dff;
$mainActiveColor:#2566df;
$pinkColor:#e81c62;
$pinkActiveColor:#d10d50;
$whiteColor:#ffffff;


/* header variable */
$mobileHeaderHeight:60px;
$webHeaderHeight: 60px;
/* header end */

$mobile:10in;
$sideWidth: 230px;