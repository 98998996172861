/* 폰트 사이즈 */
.fs-1_5{
    font-size: 2.25rem!important;
}
.fs-2_5{
    font-size: 1.875rem!important;
}
.fs-3_5{
    font-size: 1.675rem!important;
}
.fs-4_5{
    font-size: 1.375rem!important;
}
.fs-5_5{
    font-size: 1.175rem!important;
}
.fs-6_5{
    font-size: 0.85rem!important;
}
.fs-7{
    font-size: 0.8rem!important;
}
.fs-7_5{
    font-size: 0.725rem!important;
}

/*태블릿용 폰트 크기*/
@media (min-width: 600px) {
    .fs-md-1 {
        font-size: 2.5rem!important;
    }
    .fs-md-1_5{
        font-size: 2.25rem!important;
    }
    .fs-md-2 {
        font-size: 2rem!important;
    }
    .fs-md-2_5{
        font-size: 1.875rem!important;
    }
    .fs-md-3 {
        font-size: 1.75rem!important;
    }
    .fs-md-3_5{
        font-size: 1.675rem!important;
    }
    .fs-md-4 {
        font-size: 1.5rem!important;
    }
    .fs-md-4_5{
        font-size: 1.375rem!important;
    }
    .fs-md-5 {
        font-size: 1.25rem!important;
    }
    .fs-md-5_5{
        font-size: 1.175rem!important;
    }
    .fs-md-6 {
        font-size: 1rem!important;
    }
    .fs-md-6_5{
        font-size: 0.85rem!important;
    }
    .fs-md-7{
        font-size: 0.8rem!important;
    }
    .fs-md-7_5{
        font-size: 0.725rem!important;
    }
}